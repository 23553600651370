import * as firebase from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { FirebaseConfig } from "./constants";

const firebaseConfig = {
  apiKey: FirebaseConfig.REACT_APP_FIREBASE_API_KEY,
  authDomain: FirebaseConfig.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: FirebaseConfig.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: FirebaseConfig.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FirebaseConfig.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: FirebaseConfig.REACT_APP_FIREBASE_APP_ID,
  measurementId: FirebaseConfig.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
