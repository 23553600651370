export enum FirebaseConfig {
  REACT_APP_FIREBASE_API_KEY = "AIzaSyBQjbf2KPtuuo4oBhhzdeXyMVnsk_3hE04",
  REACT_APP_FIREBASE_AUTH_DOMAIN = "luxoo-appvales.firebaseapp.com",
  REACT_APP_FIREBASE_PROJECT_ID = "luxoo-appvales",
  REACT_APP_FIREBASE_STORAGE_BUCKET = "luxoo-appvales.appspot.com",
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID = "642735373541",
  REACT_APP_FIREBASE_APP_ID = "1:642735373541:web:779ec1873447198ec6e80c",
  REACT_APP_FIREBASE_MEASUREMENT_ID = "G-NJ4XMMP41G",
}

export const DRAWERWIDTH = 240;

// export const BASE_URL = "http://localhost:5000/luxoo-appvales/us-central1/api/";
export const BASE_URL = "https://us-central1-luxoo-appvales.cloudfunctions.net/api/";
export const BASE_BUCKET_URL = "https://s3.eu-central-1.wasabisys.com/luxoo.media.test";
export const DYNAMIC_LINK_REST_API = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks";
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const LARGE_REQUEST = 413;
export const INTERNAL_SERVER_ERROR = 500;

export enum ReportTypes {
  FEEDBACK = "FEEDBACK",
  GENERAL = "GENERAL",
  COMMENT = "COMMENT",
  POST = "POST",
  USER = "USER",
}

export enum ReportStatuses {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED = "APPROVED",
}
