import { Alert, Avatar, Box, Paper, Typography } from "@mui/material";
import { formatDate } from "../../services/Date";
import { getMediaUrl } from "../../services/Media";
import { UserProfileData } from "../../types/User";

interface StatusType {
  text: string;
  color: "success" | "warning" | "error" | "info";
}

const UserLongCard = ({ user }: { user: UserProfileData | null }) => {
  if (!user) return null;

  const privateStatus: StatusType = user.hidden
    ? {
        text: "Hide",
        color: "error",
      }
    : user.private
    ? {
        text: "Private",
        color: "warning",
      }
    : {
        text: "Visible",
        color: "success",
      };

  return (
    <Paper
      sx={{
        width: "100%",
        borderRadius: "50px 10px 10px 10px",
        justifyContent: "space-between",
        flexDirection: "row",
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", p: 1 }}>
        <Box sx={{ mr: 2 }}>
          <Avatar
            alt={`${user.username}-avatar`}
            src={getMediaUrl(user.photoURL)}
            sx={{ width: 100, height: 100 }}
          />
        </Box>
        <Box>
          <Typography variant='h2'>
            {user.firstName} {user.lastName} (@{user.username})
          </Typography>
          <Typography>
            <b>Title: </b> {user.title}
          </Typography>
          <Typography>
            <b>Email: </b> {user.email}
          </Typography>
          <Typography>
            <b>Date of Birth: </b> {user.dob ? formatDate(new Date(user.dob)) : "Not indicated"}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Alert variant='standard' color={privateStatus.color}>
          {privateStatus.text}
        </Alert>
      </Box>
    </Paper>
  );
};

export default UserLongCard;
