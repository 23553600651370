export const getTimeSincePost = (date: number, count: number = 2) => {
  // @ts-ignore
  let seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = seconds / 31536000;
  const ending = (val: number) => (val > 1 ? "s" : "");
  const res = [];
  if (interval > 1) {
    const years = Math.floor(interval);
    seconds = seconds - years * 31536000;
    res.push(`${years} year${ending(years)}`);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const months = Math.floor(interval);
    seconds = seconds - months * 2592000;
    res.push(`${months} month${ending(months)}`);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const days = Math.floor(interval);
    seconds = seconds - days * 86400;
    res.push(`${days} day${ending(days)}`);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const hours = Math.floor(interval);
    seconds = seconds - hours * 3600;
    res.push(`${hours} hour${ending(hours)}`);
  }
  interval = seconds / 60;
  if (interval > 1) {
    const minutes = Math.floor(interval);
    seconds = seconds - minutes * 60;
    res.push(`${minutes} minute${ending(minutes)}`);
  }
  if (seconds < 30 && count === 1) {
    res.push("A few seconds");
  } else {
    res.push(Math.floor(seconds) + " seconds");
  }
  const floorToFirst = res.slice(0, count).join(" ");
  return `${floorToFirst} ago`;
};

export const formatDate = (date: Date) => {
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
};

export const setTimeToDate = (time: string) => {
  const [hours, minutes] = time.split(":");
  const date = new Date();
  date.setHours(+hours);
  date.setMinutes(+minutes);
  return date;
};

const getFullNum = (val: number) => (val < 10 ? "0" : "") + `${val}`;

export const getTimeFromDate = (time: string) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${getFullNum(hours)}:${getFullNum(minutes)}`;
};
