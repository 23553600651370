import apiClient from "../utils/axios";

export type ID = string | number;

export const getDataByUrl = async (url: string, params?: object) => {
  const reqParams = params ? { params } : {};
  return await apiClient
    .get(url, reqParams)
    .then((response) => response?.data || null)
    .catch((err) => Promise.reject(err));
};

export const postDataByUrl = async (url: string, data: object, params?: object) => {
  const reqParams = params ? { params } : {};
  return await apiClient
    .post(url, data, reqParams)
    .then((response) => response?.data || null)
    .catch((err) => Promise.reject(err));
};

export const updateDataByUrl = async (url: string, data: object, params?: object) => {
  const reqParams = params ? { params } : {};
  return await apiClient
    .patch(url, data, reqParams)
    .then((response) => response?.data || null)
    .catch((err) => Promise.reject(err));
};

export const putDataByUrl = async (url: string, data: object, params?: object) => {
  const reqParams = params ? { params } : {};
  return await apiClient
    .put(url, data, reqParams)
    .then((response) => response?.data || null)
    .catch((err) => Promise.reject(err));
};

export const deleteDataByUrl = async (url: string) => {
  return await apiClient
    .delete(url)
    .then((response) => response?.status === 204)
    .catch((err) => Promise.reject(err));
};
