import { Box, CircularProgress, Typography } from "@mui/material";

const EmptyBox = ({
  text = "No data available",
  isLoading,
}: {
  text?: string;
  isLoading?: boolean;
}) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {isLoading ? <CircularProgress /> : <Typography color={"GrayText"}>{text}</Typography>}
  </Box>
);

export default EmptyBox;
