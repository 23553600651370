const template = `
<div style="background-color: #f4f6f8; padding-top: 20px; padding-bottom: 20px">
<table style="min-width: 300px; max-width: 600px; height: 100%; text-align: center; background-color: #ffffff; margin: auto; border-radius: 10px; padding: 10px">
  <tbody>
    <tr>
      <td style="height: 100px">
        <img
          alt="logo"
          src="https://s3.eu-central-1.wasabisys.com/luxoo.media.test/logo-dark.png"
          style="width: 120px"
        />
      </td>
    </tr>
    <tr style="min-height: 300px">
      <td style="text-align: center; min-height: 300px">
        <div>{{ content }}</div>
      </td>
    </tr>
    <tr>
      <td style="text-align: center">
        <div style="padding: 30px">
          <a
            href="{{ link }}"
            style="
              padding: 10px 30px;
              background-color: rgb(230, 193, 87);
              text-decoration: none;
              color: white;
              font-size: 20px;
              border-radius: 20px;
            "
            >Join Us</a
          >
        </div>
      </td>
    </tr>
    <tr>
      <td style="text-align: start">
        <p>
          With regards,<br />
          <b>Luxoo Administration</b>
        </p>
      </td>
    </tr>
  </tbody>
</table>
</div>
`;

export default template;
