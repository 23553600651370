import { createSlice } from "@reduxjs/toolkit";
import { getAllPermissions, updatePermission } from "../../requests/permissions";
import { getUpdatedList } from "../../services/Data";
import { PermissionsData } from "../../types/Permissions";
import { AppDispatch, RootState } from "../store";

interface InitialStateType {
  isLoading: boolean;
  error: null | string;
  roles: PermissionsData[];
}

const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  roles: [],
};

const slice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setRolesListSuccess(state, action) {
      state.roles = action.payload;
      state.isLoading = false;
    },
    setRolesListError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

export const getRolesAction = () => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading());
  getAllPermissions()
    .then((res) => dispatch(slice.actions.setRolesListSuccess(res)))
    .catch((err) => dispatch(slice.actions.setRolesListError(err)));
};

export const updateRolesAction =
  (data: PermissionsData) => (dispatch: AppDispatch, getStore: () => RootState) => {
    const { roles } = getStore();
    const updated = getUpdatedList(roles.roles, data);
    console.log(updated);

    dispatch(slice.actions.setLoading());
    updatePermission(data.id, { ...data, id: undefined })
      .then((res) => dispatch(slice.actions.setRolesListSuccess(updated)))
      .catch((err) => dispatch(slice.actions.setRolesListError(err)));
  };
