import { Alert, Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { Between } from "../../components/Cards";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ellipsis } from "../../services/Text";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { InviteData } from "../../types/Invites";

const InviteBar = ({
  invite,
  onSend,
}: {
  invite: InviteData;
  onSend: (inv: InviteData) => void;
}) => {
  const { link } = invite;
  const theme = useTheme();
  const windowSize = useWindowDimensions();
  const linkSlice = windowSize.width > 500 ? 50 : 30;
  const slicedLink = useMemo(() => ellipsis(link, linkSlice), [link, linkSlice]);

  return (
    <Paper sx={{ m: 1, p: 1 }}>
      <Between sx={{ flexWrap: "wrap" }}>
        <Box
          sx={{
            p: 1,
            pl: 3,
            pr: 3,
            mt: 1,
            mb: 1,
            backgroundColor: theme.palette.background.default,
            borderRadius: "20px",
            cursor: "pointer",
            width: 400,
          }}
        >
          <CopyToClipboard text={link} onCopy={() => console.log({ copied: link })}>
            <Typography>{slicedLink}</Typography>
          </CopyToClipboard>
        </Box>

        {invite.mail_id ? (
          <Alert>Mailed</Alert>
        ) : (
          <Button
            variant='contained'
            disabled={Boolean(invite.mail_id)}
            onClick={() => onSend(invite)}
          >
            Send via email
          </Button>
        )}
      </Between>
    </Paper>
  );
};

export default InviteBar;
