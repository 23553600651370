import { Paper, Box, Typography, Divider } from "@mui/material";
import { Between } from "../../components/Cards";

import EmptyBox from "../../components/EmptyBox";
import PostCardShort from "../../components/PostCardShort";
import { UserPostShortData } from "../../types/User";

const UserPosts = ({
  posts = [],
  isLoading,
}: {
  posts: UserPostShortData[];
  isLoading: boolean;
}) => {
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 110px)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Between sx={{ mt: 1, mb: 1, p: 1, height: 56 }}>
        <Typography variant='h3'>Posts</Typography>
      </Between>
      <Divider />
      {posts.length === 0 ? (
        <EmptyBox isLoading={isLoading} />
      ) : (
        <Box sx={{ overflowY: "scroll", height: "100%", p: 1 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {posts.map((el) => (
              <PostCardShort post={el} key={el.id} />
            ))}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default UserPosts;
