import { getDataByUrl, putDataByUrl } from ".";

export const getPost = (id: string) => getDataByUrl(`/admin/posts/${id}`);
export const deletePost = (id: string, data: { message: string }) =>
  putDataByUrl(`/admin/posts/${id}/delete`, data);
export const getComments = (id: string) => getDataByUrl(`/admin/posts/${id}/comments`);
export const getComment = (id: string, cid: string) =>
  getDataByUrl(`/admin/posts/${id}/comments${cid}`);
export const getReplies = (id: string, cid: string) =>
  getDataByUrl(`/admin/posts/${id}/comments/${cid}/replies`);
export const getReply = (id: string, cid: string, rid: string) =>
  getDataByUrl(`/admin/posts/${id}/comments/${cid}/replies/${rid}`);
