import { Button, ButtonProps } from "@mui/material";

const StyledButton = (props: ButtonProps) => (
  <Button
    variant='contained'
    size='large'
    {...props}
    sx={{
      pl: 5,
      pr: 5,
      borderRadius: 50,
      color: "white",
      textTransform: "capitalize",
      ...props.sx,
    }}
  />
);

export default StyledButton;
