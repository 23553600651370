import { Box, Paper, Typography } from "@mui/material";
import StyledButton from "../components/StyledButton";
import { useNav } from "../hooks/useNav";

const PageNotFound = ({ text }: { text?: string }) => {
  const nav = useNav();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Paper sx={{ p: 5, textAlign: "center" }}>
        <Typography sx={{ fontSize: 80, fontWeight: 800 }}>404</Typography>
        <Typography variant='h1'>{text || "Page not found"}</Typography>
        <StyledButton onClick={nav.viewUsers} sx={{ mt: 3 }}>
          Back to Home
        </StyledButton>
      </Paper>
    </Box>
  );
};

export default PageNotFound;
