import { useState } from "react";
import * as yup from "yup";
import { Paper, Typography } from "@mui/material";
import { useAuth } from "../../provider/AuthProvider";
import FormikForm from "../../components/FormikForm";
import ErrorAlert from "../../components/ErrorAlert";
import { addUser } from "../../requests/users";
import { useNav } from "../../hooks/useNav";

const UserCreation = () => {
  const auth = useAuth();
  const [error, setError] = useState("");

  const roles: string[] = auth.permissions.availableRoles;
  const invitedBy = auth?.user?.uid || null;

  const FormSchema = yup.object().shape({
    email: yup.string().required("Required").email(),
    password: yup.string().required("Required").min(6),
    username: yup.string().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    title: yup.string(),
    role: yup.string(),
  });

  const formValues = {
    email: { value: "", label: "Email*" },
    password: { value: "", label: "Password*" },
    username: { value: "", label: "Username*" },
    firstName: { value: "", label: "First Name*" },
    lastName: { value: "", label: "Last Name*" },
    title: { value: "", label: "Title" },
    role: { value: "user", label: "Role", options: roles },
  };

  const assignedValues = {
    dob: null,
    photoURL: null,
    invitedBy: invitedBy,
    private: true,
    hidden: true,
  };

  const nav = useNav();

  const handleSubmit = async (values: any) => {
    await addUser({ ...values, ...assignedValues })
      .then((res) => res.id && nav.viewUser(res.id))
      .catch((err: any) => {
        console.log("err", err.response);
        setError(err?.message || "something went wrong");
      });
  };

  return (
    <Paper sx={{ p: 2, borderRadius: "10px" }}>
      <Typography variant='h1'>User Creation</Typography>
      <Typography variant='body1'>
        Here you can create user manually. Note that for a new user, you can only specify roles you
        have permissions for.
      </Typography>
      <ErrorAlert error={error} setError={setError} />
      <FormikForm formValues={formValues} formSchema={FormSchema} onSubmit={handleSubmit} />
    </Paper>
  );
};

export default UserCreation;
