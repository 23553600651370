import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Theme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SendIcon from "@mui/icons-material/Send";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReportIcon from "@mui/icons-material/Report";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
// import HomeIcon from "@mui/icons-material/Home";
import { useAuth } from "../provider/AuthProvider";
import LogoDark from "../assets/logo-dark.png";
import { DRAWERWIDTH } from "../constants";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${DRAWERWIDTH}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWERWIDTH}px)`,
    marginLeft: `${DRAWERWIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textDecoration: "none",
    display: "flex",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  listHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  logo: {
    display: "flex",
    width: "120px",
    height: "40px",
  },
}));

interface LinkType {
  linkTo: string;
  title: string;
  Icon: any;
}

const getLinks = (sectionAccess: any) => {
  const pagesAccess = {
    users: { linkTo: "/users", title: "Users", Icon: PeopleAltIcon },
    invitations: { linkTo: "/invitations", title: "Invitations", Icon: SendIcon },
    reports: { linkTo: "/reports", title: "Reports", Icon: ReportIcon },
    administrating: {
      linkTo: "/administrating",
      title: "Administrating",
      Icon: AdminPanelSettingsIcon,
    },
  };
  const links: LinkType[] = [
    // { linkTo: "/", title: "Home", Icon: HomeIcon },
  ];
  Object.keys(pagesAccess).forEach((el) => {
    if (sectionAccess[el]) links.push((pagesAccess as any)[el]);
  });
  return links;
};

export const Dashboard = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const classes = useStyles();
  const auth = useAuth();
  const sectionAccess = auth?.permissions?.sectionAccess;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState<LinkType[]>([]);

  useEffect(() => {
    if (sectionAccess) setLinks(getLinks(sectionAccess));
  }, [sectionAccess]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOut = () => {
    auth?.logout();
    setLoading(!loading);
  };

  return (
    <Box>
      <CssBaseline />
      {!auth.user ? (
        <Box>{children}</Box>
      ) : (
        <Box className={classes.root}>
          <CssBaseline />
          <AppBar position='fixed' open={open}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <img alt='logo' className={classes.logo} src={LogoDark} />
              <IconButton onClick={handleOut}>
                <ExitToAppIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: DRAWERWIDTH,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: DRAWERWIDTH,
                boxSizing: "border-box",
              },
            }}
            variant='persistent'
            anchor='left'
            open={open}
          >
            <DrawerHeader className={classes.listHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {links.map(({ linkTo, title, Icon }) => (
                <Link className={classes.list} to={linkTo} key={title}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItem>
                </Link>
              ))}
            </List>
            <Divider />
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            {children}
          </Main>
        </Box>
      )}
    </Box>
  );
};
