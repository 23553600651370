import { Alert } from "@mui/material";

interface ErrorAlertProps {
  error: string;
  setError: (error: string) => void;
}

const ErrorAlert = ({ error, setError }: ErrorAlertProps) =>
  error ? (
    <Alert severity='error' onClose={() => setError("")} sx={{ mt: 1 }}>
      {error}
    </Alert>
  ) : null;

export default ErrorAlert;
