import { createSlice } from "@reduxjs/toolkit";
import { getAllUsers } from "../../requests/users";
import { AppDispatch } from "../store";

const initialState = {
  isLoading: false,
  error: null,
  list: [],
  last: null,
  count: null,
  query: "",
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setUsersListSuccess(state, action) {
      state.list = action.payload.data;
      state.last = action.payload.last;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    setUsersListError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setQueryFilter(state, action) {
      state.query = action.payload;
    },
  },
});

export default slice.reducer;

export const setQueryAction = (query: string) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setQueryFilter(query));
};

export const getUsersAction =
  ({ from, query }: { query?: string; from?: number }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading());
    getAllUsers({ from: from, q: query })
      .then((res) => dispatch(slice.actions.setUsersListSuccess(res)))
      .catch((err) => dispatch(slice.actions.setUsersListError(err)));
  };
