import { Button, Box } from "@mui/material";

const ShowMoreButton = ({ onShowMore = () => {} }: { onShowMore?: () => void }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
      <Button
        variant='contained'
        size='large'
        onClick={onShowMore}
        sx={{ pl: 5, pr: 5, borderRadius: 50, color: "white", textTransform: "capitalize" }}
      >
        Show more
      </Button>
    </Box>
  );
};
export default ShowMoreButton;
