import {
  Paper,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Alert,
} from "@mui/material";
import { useState } from "react";
import DeleteButton from "../../components/DeleteButton";
import { Filter } from "../../components/FormFields";

import { useAuth } from "../../provider/AuthProvider";
import { UserProfileData } from "../../types/User";

export const ChangeRoleDialog = ({ open, title, onClose, onSubmit }: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: "1.5em" }}>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const UserRole = ({
  profile,
  onRoleSet,
}: {
  profile: UserProfileData | null;
  onRoleSet: (role: string) => void;
}) => {
  const auth = useAuth();
  const [currentRole, setCurrentRole] = useState<string>(String(profile?.role || "user"));
  const [nextRole, setNextRole] = useState<null | string>(null);
  const roles: string[] = auth.permissions.availableRoles;
  const isCurrentRoleAvailable = roles.includes(currentRole);

  const handleOpenDialog = (val: string) => {
    setNextRole(val);
  };

  const handleSubmitRole = (val: string) => {
    if (nextRole) {
      onRoleSet(nextRole);
      setCurrentRole(nextRole);
      setNextRole(null);
    }
  };

  return !roles?.length || !profile ? null : (
    <Paper sx={{ mt: 2, mb: 1, p: 1, width: "100%", borderRadius: "10px" }}>
      <ChangeRoleDialog
        open={nextRole}
        title={`Are you sure you want to change @${profile.username}'s role from "${currentRole}" to "${nextRole}"`}
        onClose={() => setNextRole(null)}
        onSubmit={handleSubmitRole}
      />
      <Alert variant='outlined'>
        Current Role: <b>{currentRole}</b>
      </Alert>
      {isCurrentRoleAvailable ? (
        <Filter value={currentRole} label='User Role' onChange={handleOpenDialog} options={roles} />
      ) : null}
    </Paper>
  );
};

export interface OptionsType {
  text: string;
  action: () => void;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  disabled?: boolean;
  loading?: boolean;
  type?: string;
}

const UserAction = ({ options = [] }: { options?: OptionsType[] }) => {
  return options.length === 0 ? null : (
    <Paper sx={{ mt: 2, mb: 1, p: 1, width: "100%", borderRadius: "10px" }}>
      <ButtonGroup>
        {options.map((opt, i) => {
          if (opt?.type === "delete")
            return (
              <DeleteButton
                dialogText='Are you sure you want to delete this user with all associated data?'
                disabled={opt.disabled || opt.loading}
                onDelete={opt.action}
                variant='contained'
                color={opt?.color || "primary"}
                key={i}
              />
            );
          return (
            <Button
              disabled={opt.disabled || opt.loading}
              onClick={opt.action}
              variant='contained'
              color={opt?.color || "primary"}
              key={i}
            >
              {opt.loading && <CircularProgress color='info' size={20} sx={{ mr: 1 }} />}
              {opt.text}
            </Button>
          );
        })}
      </ButtonGroup>
    </Paper>
  );
};

export default UserAction;
