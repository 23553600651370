import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

export const Filter = ({ value, options, label, onChange }: any) => (
  <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
    <InputLabel id={`${label}-label`}>{label}</InputLabel>
    <Select
      labelId={`${label}-label`}
      id={label}
      value={value}
      label={label}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((el: any) => (
        <MenuItem value={el?.value || el} key={el?.value || el}>
          {el?.label || el}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const Input = ({ value, label, onChange }: any) => (
  <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
    <TextField id={label} value={value} label={label} onChange={(e) => onChange(e.target.value)} />
  </FormControl>
);
