import axios from "axios";
import { DYNAMIC_LINK_REST_API, FirebaseConfig } from "../constants";

export async function buildInviteLink(uid: string) {
  const invite_id = `${Date.now()}.inv.${uid}`;
  const link = `https://luxoo.page.link/?link=https://luxoo.page.link/register/${uid}/${invite_id}`;

  const { data } = await axios.post(
    DYNAMIC_LINK_REST_API,
    { longDynamicLink: link },
    { params: { key: FirebaseConfig.REACT_APP_FIREBASE_API_KEY } }
  );
  return { link: data.shortLink, invite_id };
}
