/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNav } from "../hooks/useNav";
import { useScrollList } from "../hooks/useScrollList";
import { getReplies } from "../requests/post";
import { PostSearchParams } from "../screens/post/Post";
import { getMediaUrl } from "../services/Media";
import { CommentData } from "../types/Post";
import { Between, DateCard, LikeCard } from "./Cards";

const CommentCardContent = ({
  comment,
  isSelected = false,
}: {
  comment: CommentData;
  isSelected?: boolean;
}) => {
  const { viewUser } = useNav();
  const { author, message, createdAt, likes } = comment;
  const viewAuthor = () => viewUser(author.id);
  const pointer = { cursor: "pointer" };
  const selectedBg = useTheme().palette.primary.light;

  return (
    <Box
      sx={[
        { width: "100%", height: "100%", display: "flex" },
        isSelected ? { backgroundColor: selectedBg, p: 1 } : {},
      ]}
    >
      <Avatar
        onClick={viewAuthor}
        alt={`${author.username}-avatar`}
        src={getMediaUrl(author.photoURL)}
        sx={{ width: 40, height: 40, mr: 1, ...pointer }}
      />
      <Box sx={{ width: "100%" }}>
        <Typography>
          <b style={pointer} onClick={viewAuthor}>{`@${author.username}: `}</b>
          {`${message}`}
        </Typography>
        <Between>
          <DateCard date={createdAt} />
          <LikeCard count={likes.length} />
        </Between>
        <Divider />
      </Box>
    </Box>
  );
};

const ShowRepliesBtn = ({
  open,
  count,
  onChange,
  isLoading,
}: {
  open: boolean;
  count: number;
  onChange: () => void;
  isLoading: boolean;
}) => (
  <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
    {isLoading && <CircularProgress size={"small"} />}
    <Button size='small' onClick={onChange}>{`${
      open ? "Hide" : `Show (${count})`
    } Replies`}</Button>
  </Box>
);

const CommentCard = ({
  comment,
  postId,
  searchParams,
}: {
  comment: CommentData;
  postId: string;
  searchParams?: PostSearchParams;
}) => {
  const { repliesCount } = comment;
  const isSelected = Boolean(searchParams?.cid === comment.id);
  const hasReplySelected = Boolean(searchParams?.rid);
  const selectedReplyId = hasReplySelected && searchParams?.rid;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState<CommentData[]>([]);

  const getCommentReplies = () => {
    setLoading(true);
    getReplies(postId, comment.id)
      .then((res) => {
        setReplies(res);
        setLoading(false);
        setOpen(true);
      })
      .catch((err) => {
        console.log("ON REPLY ERROR: ", err);
        setLoading(false);
      });
  };

  const handleOpen = async () => {
    if (open) setOpen(false);
    else {
      if (!replies.length) await getCommentReplies();
      else setOpen(true);
    }
  };

  const { refs, handleScroll } = useScrollList(replies);

  useEffect(() => {
    if (isSelected && selectedReplyId) {
      handleOpen();
      handleScroll(selectedReplyId);
    }
  }, [selectedReplyId]);

  return (
    <Box>
      <CommentCardContent comment={comment} isSelected={isSelected && !hasReplySelected} />
      {repliesCount > 0 ? (
        <ShowRepliesBtn
          open={open}
          count={repliesCount}
          onChange={handleOpen}
          isLoading={loading}
        />
      ) : null}
      <Box sx={{ ml: 5 }}>
        {replies.length && open
          ? replies.map((el) => (
              <div ref={refs[el.id]} key={el.id}>
                <CommentCardContent
                  comment={el}
                  isSelected={hasReplySelected && el.id === searchParams?.rid}
                />
              </div>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default CommentCard;
