import { createSlice } from "@reduxjs/toolkit";
import { getComments, getPost } from "../../requests/post";
import { CommentData, PostData } from "../../types/Post";
import { AppDispatch } from "../store";

interface InitialStateType {
  isPostLoading: boolean;
  isCommentsLoading: boolean;
  post: null | PostData;
  comments: CommentData[];
  error: {
    post: null | string;
    comments: null | string;
  };
}

const initialState: InitialStateType = {
  isPostLoading: false,
  isCommentsLoading: false,
  post: null,
  comments: [],
  error: {
    post: null,
    comments: null,
  },
};

const slice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPostLoading(state) {
      state.isPostLoading = true;
    },
    setPostSuccess(state, action) {
      state.post = action.payload;
      state.isPostLoading = false;
    },
    setPostError(state, action) {
      state.error.post = action.payload;
      state.isPostLoading = false;
    },

    setCommentsLoading(state) {
      state.isCommentsLoading = true;
    },
    setCommentsSuccess(state, action) {
      state.comments = action.payload;
      state.isCommentsLoading = false;
    },
    setCommentsError(state, action) {
      state.error.comments = action.payload;
      state.isCommentsLoading = false;
    },
  },
});

export const getPostAction =
  ({ id }: { id: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setPostLoading());
    getPost(id)
      .then((res) => dispatch(slice.actions.setPostSuccess(res)))
      .catch((err) => dispatch(slice.actions.setPostError(err)));
  };

export const getPostCommentsAction =
  ({ id }: { id: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setCommentsLoading());
    getComments(id)
      .then((res) => dispatch(slice.actions.setCommentsSuccess(res)))
      .catch((err) => dispatch(slice.actions.setCommentsError(err)));
  };

export default slice.reducer;
