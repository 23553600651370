import { Button, ButtonProps, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useState } from "react";

export const DeleteDialog = ({ open, title, onClose, onDelete }: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontSize: "1.5em" }}>{title}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color='primary' variant='contained'>
          Cancel
        </Button>
        <Button onClick={onDelete} color='error' variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DeleteButtonProps {
  dialogText?: string;
  buttonText?: string;
  deleteTarget?: string;
  onDelete: () => void;
}

const DeleteButton = ({
  dialogText = "A you sure you want to delete this?",
  buttonText = "Delete",
  deleteTarget,
  onDelete,
  ...props
}: DeleteButtonProps & ButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const dialogTitle = deleteTarget ? `A you sure you want to delete ${deleteTarget}?` : dialogText;

  return (
    <>
      <DeleteDialog
        open={open}
        title={dialogTitle}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
      />
      <Button onClick={handleOpenDialog} color='error' {...props}>
        {buttonText}
      </Button>
    </>
  );
};

export default DeleteButton;
