import { getDataByUrl, postDataByUrl } from ".";
import { MessageData, TemplateData } from "../types/Invites";

export const getInvitations = () => getDataByUrl(`/admin/invitations/`);
export const getTemplates = () => getDataByUrl(`/admin/invitations/templates/`);
export const createInvitation = (data: object) => postDataByUrl(`/admin/invitations/`, data);
export const sentInvitationEmail = (data: MessageData) =>
  postDataByUrl(`/admin/invitations/send-email/`, data);
export const saveEmailTemplate = (data: TemplateData) =>
  postDataByUrl(`/admin/invitations/templates/`, data);
