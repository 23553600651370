import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PermissionsData, RoleType } from "../../types/Permissions";

const RoleUpdateForm = ({
  currentRole,
  roles,
  onUpdate,
}: {
  currentRole: PermissionsData;
  roles: PermissionsData[];
  onUpdate: (data: PermissionsData) => void;
}) => {
  const [roleValues, setRoleValues] = useState<PermissionsData>(currentRole);
  const allAvailableRoles = roles.map((el) => el.id).sort();
  const allAvailableSections = Object.keys(roleValues.sectionAccess).sort();

  useEffect(() => {
    setRoleValues(currentRole);
  }, [currentRole]);

  const handleUpdateRoll = () => {
    onUpdate(roleValues);
  };

  const handleSetDashboardAccess = () =>
    setRoleValues((prev) => ({ ...prev, dashboardAccess: !prev.dashboardAccess }));

  const handleSetSectionAccess = (key: string, value: boolean) =>
    setRoleValues((prev) => ({
      ...prev,
      sectionAccess: {
        ...prev.sectionAccess,
        [key]: value,
      },
    }));

  const handleSetAvailableRoles = (role: RoleType) => {
    if (roleValues.availableRoles.includes(role)) {
      setRoleValues((prev) => ({
        ...prev,
        availableRoles: prev.availableRoles.filter((el) => el !== role),
      }));
    } else {
      setRoleValues((prev) => ({
        ...prev,
        availableRoles: [...prev.availableRoles, role],
      }));
    }
  };

  return (
    <Box>
      <Typography variant='h2' sx={{ textTransform: "capitalize" }}>
        {roleValues.id}
      </Typography>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <FormControlLabel
        control={
          <Checkbox checked={roleValues.dashboardAccess} onChange={handleSetDashboardAccess} />
        }
        label={"Has dashboard access"}
      />
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Typography variant='h4'>Section Access</Typography>
      <Typography variant='caption'>
        Select the sections in this dashboard that the user will have access to
      </Typography>
      <FormGroup row>
        {allAvailableSections.map((section) => {
          const isAvailable = (roleValues.sectionAccess as any)[section];
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAvailable}
                  onChange={() => handleSetSectionAccess(section, !isAvailable)}
                />
              }
              label={section}
            />
          );
        })}
      </FormGroup>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Typography variant='h4'>Available Roles</Typography>
      <Typography variant='caption'>
        Roles that are available when creating or updating users.
      </Typography>
      <FormGroup row>
        {allAvailableRoles.map((role) => {
          const isAvailable = roleValues.availableRoles.includes(role);
          return (
            <FormControlLabel
              control={
                <Checkbox checked={isAvailable} onChange={() => handleSetAvailableRoles(role)} />
              }
              label={role}
            />
          );
        })}
      </FormGroup>
      <Button fullWidth variant='contained' sx={{ mt: 2 }} onClick={handleUpdateRoll}>
        Update Role
      </Button>
    </Box>
  );
};

export default RoleUpdateForm;
