import { getDataByUrl, putDataByUrl, postDataByUrl, deleteDataByUrl } from ".";

export const getAllUsers = (params?: { q?: string; from?: number }) =>
  getDataByUrl(`/admin/users/`, params);

export const getUser = (id: string) => getDataByUrl(`/admin/users/${id}`);

export const getUserPosts = (id: string) => getDataByUrl(`/admin/users/${id}/posts/`);

export const getUserSpecificPost = (userId: string, pId: string) =>
  getDataByUrl(`/admin/users/${userId}/posts/${pId}/`);

export const getUserMedia = (id: string) => getDataByUrl(`/admin/users/${id}/media/`);

export const putUserStatus = (id: string, data: object) =>
  putDataByUrl(`/admin/users/${id}/status/`, data);

export const putUserRole = (id: string, data: object) =>
  putDataByUrl(`/admin/users/${id}/role/`, data);

export const addUser = (data: object) => postDataByUrl(`/admin/users/`, data);

export const deleteUser = (id: string) => deleteDataByUrl(`/admin/users/${id}`);
