import {
  Paper,
  Box,
  useTheme,
  TextField,
  Typography,
  Alert,
  Divider,
  IconButton,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../provider/AuthProvider";
import { Between } from "../../components/Cards";
import { getTimeSincePost } from "../../services/Date";
import StyledButton from "../../components/StyledButton";
import { sendMessageToChat, useMessages } from "../../utils/messages";
import EmptyBox from "../../components/EmptyBox";
import CloseIcon from "@mui/icons-material/Close";

export interface MessageData {
  createdAt: number;
  received: boolean;
  sent: boolean;
  text: string;
  user: { _id: string };
}

const MessageBubble = ({ message }: { message: MessageData }) => {
  console.log(message);

  const { user } = useAuth();
  const theme = useTheme();
  const isReceived = message.user._id !== user?.uid;
  return (
    <Box
      sx={{ display: "flex", justifyContent: isReceived ? "flex-start" : "flex-end", mt: 1, mb: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: isReceived ? "flex-start" : "flex-end",
          alignItems: isReceived ? "flex-start" : "flex-end",
        }}
      >
        <Box
          sx={{
            backgroundColor: isReceived
              ? theme.palette.background.default
              : theme.palette.secondary.main,
            color: isReceived ? "black" : "white",
            borderRadius: 3,
            maxWidth: 500,
            p: 2,
          }}
        >
          <Typography sx={{ whiteSpace: "pre-line" }}>{message.text}</Typography>
        </Box>
        <Typography variant='caption' color={"GrayText"}>
          {getTimeSincePost(message.createdAt, 1)}
        </Typography>
      </Box>
    </Box>
  );
};

const MessageInput = ({ onSend }: { onSend: (message: string) => void }) => {
  const [message, setMessage] = useState("");
  const theme = useTheme();
  const handleSend = () => {
    onSend(message.trim());
    setMessage("");
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSend();
      }}
    >
      <Between
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: "0 0 10px 10px",
          width: "100%",
          p: 2,
        }}
      >
        <TextField
          fullWidth
          multiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant='standard'
        />
        <StyledButton
          type='submit'
          variant='contained'
          sx={{ ml: 1, pl: 2, pr: 2 }}
          disabled={!message}
        >
          Send
        </StyledButton>
      </Between>
    </form>
  );
};

const UserMessenger = ({
  userChatId,
  userId,
  adminId,
  closeMessenger,
}: {
  userChatId: string;
  userId: string;
  adminId: string;
  closeMessenger: () => void;
}) => {
  const [messages, loading, error] = useMessages(userChatId);
  const isMapped = Array.isArray(messages) && messages.length > 0;
  const bottomRef: any = useRef();

  const onSend = (message: string) => {
    sendMessageToChat({
      thread_id: userChatId,
      targetId: userId,
      authorId: adminId,
      message,
    });
  };

  useEffect(() => {
    if (bottomRef?.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 110px)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Between sx={{ mt: 1, mb: 1, p: 1, height: 56 }}>
        <Typography variant='h3'>Chat</Typography>
        <IconButton onClick={closeMessenger}>
          <CloseIcon />
        </IconButton>
      </Between>
      <Divider />
      {error ? <Alert severity='error'>{error}</Alert> : null}
      {loading ? (
        <EmptyBox isLoading={Boolean(loading)} />
      ) : (
        <Box sx={{ overflowY: "scroll", height: "100%", p: 2 }}>
          {isMapped &&
            messages.reverse().map((el: any, i) => <MessageBubble message={el} key={i} />)}
          <span ref={bottomRef} />
        </Box>
      )}
      <MessageInput onSend={onSend} />
    </Paper>
  );
};

export default UserMessenger;
