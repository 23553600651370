import { Paper } from "@mui/material";
import { UserCard } from "../../components/Cards";
import { UserProfileData } from "../../types/User";

const UserShortCard = ({ user }: { user: UserProfileData | null }) => {
  if (!user) return null;
  return (
    <Paper
      sx={{
        width: "100%",
        borderRadius: "50px 10px 10px 50px",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <UserCard user={user} />
    </Paper>
  );
};

export default UserShortCard;
