import { createSlice } from "@reduxjs/toolkit";
import { getUser, getUserPosts, putUserStatus } from "../../requests/users";
import {
  UserFollowedData,
  UserFollowersData,
  UserInvitesData,
  UserPostShortData,
  UserProfileData,
} from "../../types/User";
import { AppDispatch } from "../store";

interface InitialStateType {
  isProfileLoading: boolean;
  isProfileUpdating: boolean;
  isPostsLoading: boolean;
  isMediaLoading: boolean;
  error: {
    profile: null | string;
    posts: null | string;
    media: null | string;
  };
  profile: null | UserProfileData;
  followers: null | UserFollowersData;
  followed: null | UserFollowedData;
  invites: null | UserInvitesData;
  posts: UserPostShortData[];
  postLastIndex: null | number;
  media: [];
}

const initialState: InitialStateType = {
  isProfileLoading: false,
  isProfileUpdating: false,
  isPostsLoading: false,
  isMediaLoading: false,
  error: {
    profile: null,
    posts: null,
    media: null,
  },
  profile: null,
  followers: null,
  followed: null,
  invites: null,
  posts: [],
  postLastIndex: null,
  media: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProfileLoading(state) {
      state.isProfileLoading = true;
    },
    setUserProfileUpdating(state) {
      state.isProfileUpdating = true;
    },
    setUserProfileSuccess(state, action) {
      state.profile = action.payload.profile;
      state.followers = action.payload.followers;
      state.followed = action.payload.followed;
      state.invites = action.payload.invites;
      state.isProfileLoading = false;
    },
    updateUserStatusSuccess(state, action) {
      state.profile = action.payload;
      state.isProfileUpdating = false;
    },
    setUserProfileError(state, action) {
      state.error.profile = action.payload;
      state.isProfileLoading = false;
    },

    setUserPostsLoading(state) {
      state.isPostsLoading = true;
    },
    setUserPostsSuccess(state, action) {
      state.posts = action.payload.data;
      state.postLastIndex = action.payload.last;
      state.isPostsLoading = false;
    },
    setUserPostsError(state, action) {
      state.error.posts = action.payload;
      state.isPostsLoading = false;
    },

    setUserMediaLoading(state) {
      state.isMediaLoading = true;
    },
    setUserMediaSuccess(state, action) {
      state.media = action.payload;
      state.isMediaLoading = false;
    },
    setUserMediaError(state, action) {
      state.error.media = action.payload;
      state.isMediaLoading = false;
    },
  },
});

export const getUserProfileAction =
  ({ id }: { id: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setUserProfileLoading());
    getUser(id)
      .then((res) => dispatch(slice.actions.setUserProfileSuccess(res)))
      .catch((err) => dispatch(slice.actions.setUserProfileError(err)));
  };

export const getUserPostsAction =
  ({ id }: { id: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setUserPostsLoading());
    getUserPosts(id)
      .then((res) => dispatch(slice.actions.setUserPostsSuccess(res)))
      .catch((err) => dispatch(slice.actions.setUserPostsError(err)));
  };

export const setUserStatusAction =
  ({ id, hidden, profile }: { id: string; hidden: boolean; profile: UserProfileData }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setUserProfileUpdating());
    putUserStatus(id, { hidden })
      .then((res) => dispatch(slice.actions.updateUserStatusSuccess({ ...profile, hidden })))
      .catch((err) => dispatch(slice.actions.setUserProfileError(err)));
  };

export default slice.reducer;
