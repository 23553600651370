import { Box } from "@mui/material";
import { useNav } from "../hooks/useNav";
import { getMediaUrl } from "../services/Media";
import { UserPostShortData } from "../types/User";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const PostCardShort = ({ post }: { post: UserPostShortData }) => {
  const { viewPost } = useNav();
  const isVideo = post?.mimeType?.includes("video");
  return (
    <Box
      sx={{ width: 100, height: 100, m: "1px", position: "relative" }}
      onClick={() => viewPost(post.id)}
    >
      <Box
        id={post.id}
        component='img'
        src={getMediaUrl(post?.preview || post.media)}
        sx={{ width: "100%", height: "100%", borderRadius: "2px", cursor: "pointer" }}
      />
      {isVideo ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PlayArrowIcon fontSize='large' color={"primary"} />
        </Box>
      ) : null}
    </Box>
  );
};

export default PostCardShort;
