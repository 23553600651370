import { useState, ChangeEvent } from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { Input, Theme } from "@mui/material";
import * as EmailValidator from "email-validator";
import Image from "../assets/main.png";
import LogoDark from "../assets/logo-dark.png";

import { useAuth } from "../provider/AuthProvider";
import StyledButton from "../components/StyledButton";
import Loader from "../components/Loader";
import ErrorAlert from "../components/ErrorAlert";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    position: "absolute",
    left: 0,
    width: "100%",
    overflow: "hidden",
    display: "block",
    background: "#F5F6FA",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  layer: {
    height: "100%",
    position: "absolute",
    left: 0,
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "rgba(19, 8, 33, 0.8)",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  line: {
    display: "block",
    width: "10%",
    height: "15%",
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    padding: theme.spacing(6),
    borderRadius: 10,
    boxShadow: theme.shadows[8],
  },
  logo: {
    display: "flex",
    width: "180px",
    height: "70px",
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  subTitle: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(5),
  },
  form: {
    width: "300px",
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const auth = useAuth();

  const inputValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (data.email && data.password) {
      if (EmailValidator.validate(data.email)) {
        setLoading(true);
        auth.login({
          email: data.email,
          pass: data.password,
          onError: () => {
            setError("Invalid credentials");
            setLoading(false);
          },
        });
      } else {
        setError("Wrong email format!");
      }
    } else {
      setError("Please fill in the fields first!");
    }
  };

  return (
    <Box className={classes.root}>
      <Loader open={loading} />
      <Box className={classes.layer}>
        <Box className={classes.content}>
          <Box className={classes.line}></Box>
          <Box className={classes.body}>
            <img alt='logo' className={classes.logo} src={LogoDark} />
            <Box className={classes.formBox}>
              <form className={classes.form} noValidate autoComplete='on' onSubmit={handleSubmit}>
                <Input
                  placeholder='Email'
                  name='email'
                  className={classes.form}
                  value={data.email}
                  onChange={inputValueChange}
                  sx={{ mb: 3 }}
                />
                <Input
                  type='password'
                  placeholder='Password'
                  name='password'
                  className={classes.form}
                  value={data.password}
                  onChange={inputValueChange}
                />
                <ErrorAlert error={error} setError={setError} />

                <StyledButton
                  sx={{ mt: 2 }}
                  type='submit'
                  className={classes.submitButton}
                  variant='contained'
                  color='primary'
                >
                  Login
                </StyledButton>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
