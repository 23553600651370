import { createSlice } from "@reduxjs/toolkit";
import {
  getInvitations,
  createInvitation,
  getTemplates,
  saveEmailTemplate,
  sentInvitationEmail,
} from "../../requests/invitations";
import { getUpdatedList } from "../../services/Data";
import { InviteData, MessageData, TemplateData } from "../../types/Invites";
import { buildInviteLink } from "../../utils/links";
import { AppDispatch, RootState } from "../store";

interface InitialStateType {
  isLoading: boolean;
  isTemplatesLoading: boolean;
  templates: TemplateData[];
  error: null | string;
  invitedUsers: InviteData[];
  invitesUsed: number;
  invitesCount: number;
}

const initialState: InitialStateType = {
  isLoading: false,
  isTemplatesLoading: false,
  templates: [],
  error: null,
  invitedUsers: [],
  invitesUsed: 0,
  invitesCount: 0,
};

const slice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setInvitesSuccess(state, action) {
      state.invitedUsers = action.payload.invitedUsers;
      state.invitesUsed = action.payload.invitesUsed;
      state.invitesCount = action.payload.invitesCount;
      state.isLoading = false;
    },
    updateInvitesSuccess(state, action) {
      state.invitedUsers = action.payload.invitedUsers;
      state.isLoading = false;
    },
    setInvitesError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setTemplatesLoading(state) {
      state.isTemplatesLoading = true;
    },
    setTemplatesSuccess(state, action) {
      state.templates = action.payload;
      state.isTemplatesLoading = false;
    },
    setTemplatesError(state, action) {
      state.error = action.payload;
      state.isTemplatesLoading = false;
    },
  },
});

export const getInvitationsAction = () => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading());
  getInvitations()
    .then((res) => dispatch(slice.actions.setInvitesSuccess(res)))
    .catch((err) => dispatch(slice.actions.setInvitesError(err)));
};

export const getTemplatesAction = () => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setTemplatesLoading());
  getTemplates()
    .then((res) => dispatch(slice.actions.setTemplatesSuccess(res)))
    .catch((err) => dispatch(slice.actions.setTemplatesError(err)));
};

export const saveTemplatesAction =
  (data: TemplateData) => (dispatch: AppDispatch, getStore: () => RootState) => {
    dispatch(slice.actions.setTemplatesLoading());
    saveEmailTemplate(data)
      .then((res) => {
        const { invitations } = getStore();
        const updated = getUpdatedList(invitations.templates, { ...data, id: res?.id });
        dispatch(slice.actions.setTemplatesSuccess(updated));
      })
      .catch((err) => dispatch(slice.actions.setTemplatesError(err)));
  };

export const createInvitationAction = (uid: string) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading());
  buildInviteLink(uid).then((data) => {
    if (data.link && data.invite_id) {
      createInvitation(data)
        .then((res) => dispatch(slice.actions.setInvitesSuccess(res)))
        .catch((err) => dispatch(slice.actions.setInvitesError(err)));
    } else {
      dispatch(slice.actions.setInvitesError("Cannot create invite link"));
    }
  });
};

export const sendEmailAction =
  (data: MessageData, currentInvite: InviteData) =>
  (dispatch: AppDispatch, getStore: () => RootState) => {
    dispatch(slice.actions.setLoading());
    sentInvitationEmail(data)
      .then((res) => {
        const { invitations } = getStore();
        const updated = getUpdatedList(
          invitations.invitedUsers,
          { ...currentInvite, mail_id: res.mail_id },
          "invite_id"
        );
        dispatch(slice.actions.updateInvitesSuccess({ invitedUsers: updated }));
      })
      .catch((err) => dispatch(slice.actions.setInvitesError(err)));
  };

export default slice.reducer;
