import { useLocation } from "react-router-dom";
import TabsContainer from "../../components/TabsContainer";
import RoleManagement from "./RoleManagement";
import UserCreation from "./UserCreation";

const Administrating = () => {
  const search = new URLSearchParams(useLocation().search);
  const initialIndex = Number(search.get("init") || 0);

  const tabs = [
    { label: "User Creation", Component: UserCreation },
    { label: "Role Management", Component: RoleManagement },
  ];

  return <TabsContainer initialIndex={initialIndex} tabs={tabs} />;
};

export default Administrating;
