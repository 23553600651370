import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import Loader from "./Loader";

const getInitialValues = (object: any) => {
  const res = {};
  for (const [key, value] of Object.entries(object)) {
    // @ts-ignore
    res[key] = value.value;
  }
  return res;
};

const FormikForm = ({
  formValues,
  formSchema,
  onSubmit,
}: {
  formValues: any;
  formSchema: any;
  onSubmit: any;
}) => {
  const initialValues = getInitialValues(formValues);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      await onSubmit(values);
      // resetForm();
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Loader open={isSubmitting} />
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        {Object.keys(formValues).map((key, i) => {
          const curTouched = (touched as any)[key];
          const curError = (errors as any)[key];
          const cur = (formValues as any)[key];
          const label = cur.label;
          if (!cur.options) {
            return (
              <Box sx={{ mb: 1, mt: 2 }} key={i}>
                <TextField
                  fullWidth
                  {...getFieldProps(key)}
                  label={label}
                  error={Boolean(curTouched && curError)}
                />
                <FormHelperText error>{curTouched && curError}</FormHelperText>
              </Box>
            );
          } else {
            return (
              <Box sx={{ mb: 1, mt: 2 }} key={i}>
                <FormControl fullWidth>
                  <InputLabel id={`${label}-label`}>{label}</InputLabel>
                  <Select
                    sx={{ textTransform: "capitalize" }}
                    labelId={`${label}-label`}
                    id={label}
                    label={label}
                    {...getFieldProps(key)}
                  >
                    {cur.options.map((el: any) => (
                      <MenuItem
                        value={el?.value || el}
                        key={el?.value || el}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {el?.label || el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            );
          }
        })}
        <Button type='submit' variant='contained' fullWidth disabled={isSubmitting} sx={{ mt: 1 }}>
          Submit
        </Button>
      </Form>
    </FormikProvider>
  );
};

export default FormikForm;
