import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../components/Loader";
import { useNav } from "../../hooks/useNav";
import { useAuth } from "../../provider/AuthProvider";
import { deleteUser, putUserRole } from "../../requests/users";

import {
  getUserPostsAction,
  getUserProfileAction,
  setUserStatusAction,
} from "../../store/slices/user";
import { RootState } from "../../store/store";
import { createChat, useChatCheck } from "../../utils/messages";
import PageNotFound from "../PageNotFound";
import FilteredUsersList from "./FilteredUsersList";
import MessageModal from "./MessageModal";
import UserAction, { OptionsType, UserRole } from "./UserAction";
import UserLongCard from "./UserLongCard";
import UserMessenger from "./UserMessenger";
import UserPosts from "./UserPosts";

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  photoUrl: string;
  title: string;
}

const User = () => {
  const params = useParams();
  const userId = params?.userId;
  const dispatch = useDispatch();
  const { user, permissions } = useAuth();

  const { isCheckingChats, isChatExist, chatId } = useChatCheck(userId);
  const [isChatOpen, setChatOpen] = useState(false);
  const [isChatLoading, setChatLoading] = useState(false);
  const [isLocalLoading, setLocalLoading] = useState(false);
  const [userChatId, setUserChatId] = useState<string | null>(null);
  const [isMessageModalOpen, setMessageModalOpen] = useState(false);

  const {
    profile,
    followers,
    followed,
    posts,
    isProfileLoading,
    isProfileUpdating,
    isPostsLoading,
  } = useSelector((state: RootState) => state.user);
  const isHidden = profile?.hidden;

  const roles: string[] = permissions.availableRoles;
  const role = profile?.role ? profile.role : "user";
  const isCurrentRoleAvailable = roles.includes(String(role));

  const handleSubmitInitialMessage = async (message: string) => {
    setMessageModalOpen(false);
    if (userId && message && user?.uid) {
      setChatLoading(true);
      const chatId = await createChat({ targetId: userId, authorId: user?.uid, message });
      setUserChatId(chatId);
      setChatLoading(false);
      setChatOpen(true);
    }
  };

  const handleStartConversation = () => {
    if (isChatExist) {
      console.log("Open existing chat", chatId);
      setUserChatId(chatId);
      setChatOpen(true);
    } else {
      console.log("Create chat with initial message");
      setMessageModalOpen(true);
    }
  };

  const handleChangeStatus = () => {
    if (userId && profile)
      dispatch(setUserStatusAction({ id: userId, hidden: !isHidden, profile }));
  };

  const nav = useNav();

  const handleDeleteUser = () => {
    if (userId && profile?.id) {
      setLocalLoading(true);
      deleteUser(profile.id)
        .then((success) => success && setTimeout(nav.viewUsers, 1000))
        .catch((err) => alert(err?.message || err));
    }
  };

  const handleUserRoleUpdate = (role: string) => {
    if (userId && profile?.id) {
      putUserRole(profile.id, { role }).catch((err) => alert(err?.message || err));
    }
  };

  useEffect(() => {
    setChatOpen(false);
    setChatLoading(false);
    setUserChatId(null);
    setMessageModalOpen(false);
    if (userId) {
      dispatch(getUserProfileAction({ id: userId }));
      dispatch(getUserPostsAction({ id: userId }));
    }
  }, [dispatch, userId]);

  const options: OptionsType[] = [
    {
      text: isChatExist ? "View chat with user" : "Start conversation",
      action: handleStartConversation,
      disabled: isCheckingChats,
      loading: isChatLoading,
    },
    {
      text: isHidden ? "Make profile public" : "Hide (ban) this profile",
      action: handleChangeStatus,
      color: isHidden ? "info" : "error",
      loading: isProfileUpdating,
    },
    {
      text: "Delete user",
      action: handleDeleteUser,
      disabled: !isCurrentRoleAvailable,
      color: "error",
      type: "delete",
    },
  ];

  const loading = isProfileLoading || isCheckingChats || isLocalLoading;

  if (loading) return <Loader />;
  if (!loading && !profile) return <PageNotFound text='User not found' />;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <MessageModal
        open={isMessageModalOpen}
        onClose={() => setMessageModalOpen(false)}
        onSubmit={handleSubmitInitialMessage}
      />
      <Box sx={{ width: "calc(100% - 460px)" }}>
        <UserLongCard user={profile} />
        <UserAction options={options} />
        <UserRole profile={profile} onRoleSet={handleUserRoleUpdate} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FilteredUsersList title='Followers' users={followers?.followers || []} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FilteredUsersList title='Followed' users={followed?.followedBy || []} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "450px" }}>
        {isChatOpen && userChatId && user?.uid && userId ? (
          <UserMessenger
            userChatId={userChatId}
            adminId={user.uid}
            userId={userId}
            closeMessenger={() => setChatOpen(false)}
          />
        ) : (
          <UserPosts posts={posts} isLoading={isPostsLoading} />
        )}
      </Box>
    </Box>
  );
};

export default User;
