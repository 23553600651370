import { Box, Divider, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EmptyBox from "../../components/EmptyBox";
import SearchBar from "../../components/SearchBar";
import { UserProfileData } from "../../types/User";
import UserShortCard from "./UserShortCard";

const FilteredUsersList = ({
  users = [],
  title = "",
}: {
  users: UserProfileData[];
  title: string;
}) => {
  const [filteredList, setFilteredList] = useState<UserProfileData[]>([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query) {
      const filtered = users.filter((el) =>
        String(`${el.firstName} ${el.lastName} ${el.username}`)
          .toLowerCase()
          .includes(query.toLowerCase())
      );
      setFilteredList(filtered);
    } else {
      setFilteredList(users);
    }
  }, [users, query]);

  const activeList = query.length ? filteredList : users;

  return (
    <Paper sx={{ mt: 1, p: 1, borderRadius: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
          mb: 1,
        }}
      >
        <Typography variant='h3' sx={{ mr: 2 }}>
          {title}
        </Typography>
        <SearchBar value={query} setValue={setQuery} timeout={100} />
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ overflowY: "scroll", minHeight: 400, height: "100%", p: 1 }}>
        {activeList.length === 0 ? (
          <EmptyBox />
        ) : (
          activeList.map((el) => <UserShortCard user={el} key={el.id} />)
        )}
      </Box>
    </Paper>
  );
};

export default FilteredUsersList;
