/* eslint-disable react-hooks/exhaustive-deps */
import { signInWithEmailAndPassword, signOut, User } from "@firebase/auth";
import React, { useContext, useState, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import { auth } from "../firebase";
import { getPermissions } from "../requests/profile";
import { setTokenInstance } from "../utils/axios";

interface AppContextInterface {
  user: User | null;
  permissions: any;
  setPermissions: (data: any) => void;
  getProfilePermissions: () => void;
  isInitializing: boolean;
}
interface LoginParams {
  email: string;
  pass: string;
  onError?: (mes: string) => void;
}

export const AuthContext = React.createContext<AppContextInterface | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const login = async ({ email, pass, onError }: LoginParams) => {
    await signInWithEmailAndPassword(auth, email, pass)
      .then(() => navigate(`/`))
      .catch((err) => {
        console.error(err);
        if (onError) onError(err.message);
      });
  };

  const logout = async () => {
    await signOut(auth)
      .then(() => console.log("User signed out!"))
      .catch(console.error);
  };

  return { login, logout, ...context };
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isInitializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [permissions, setPermissions] = useState(null);

  const getProfilePermissions = async () => {
    await getPermissions()
      .then((userRole) => {
        if (userRole?.permissions) {
          if (userRole.permissions.dashboardAccess) {
            setPermissions(userRole.permissions);
          } else {
            alert("Access denied");
            signOut(auth);
          }
        } else {
          alert("Sorry, server not available right now...");
          signOut(auth);
        }
      })
      .catch((err) => {
        alert("Access denied");
        signOut(auth);
      });
  };

  const authStateCallback = async (currentUser: any) => {
    if (!isInitializing) setInitializing(true);
    if (currentUser) {
      const tokenResult = await auth?.currentUser?.getIdTokenResult();
      if (tokenResult?.token) {
        // console.log(tokenResult.token);
        setTokenInstance(tokenResult.token);
        await getProfilePermissions();
      }
    } else {
      setTokenInstance(undefined);
      setPermissions(null);
    }
    setUser(currentUser);
    setInitializing(false);
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(authStateCallback);
    return subscriber; // unsubscribe on unmount
  }, []);

  const value: AppContextInterface = {
    user,
    permissions,
    setPermissions,
    getProfilePermissions,
    isInitializing,
  };

  if (isInitializing) return <Loader />;
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
