import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../components/FormFields";
import Loader from "../../components/Loader";
import { getRolesAction, updateRolesAction } from "../../store/slices/roles";
import { RootState } from "../../store/store";
import { PermissionsData } from "../../types/Permissions";
import RoleUpdateForm from "./RoleUpdateForm";

const RoleManagement = () => {
  const [currentRole, setCurrentRole] = useState<null | PermissionsData>(null);
  const { roles, isLoading } = useSelector((state: RootState) => state.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesAction());
  }, [dispatch]);

  const onRoleUpdate = (data: PermissionsData) => {
    dispatch(updateRolesAction(data));
  };

  const options = roles.map((el) => ({ label: el.id, value: el }));

  return (
    <Paper sx={{ p: 2, borderRadius: "10px" }}>
      <Loader open={isLoading} />
      <Typography variant='h1'>Role Management</Typography>
      <Typography variant='body1'>Here you can set up permissions for different roles.</Typography>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Filter
          value={currentRole}
          label='Role'
          onChange={(val: any) => setCurrentRole(val)}
          options={options}
        />
      </Box>
      {currentRole && (
        <RoleUpdateForm currentRole={currentRole} roles={roles} onUpdate={onRoleUpdate} />
      )}
    </Paper>
  );
};

export default RoleManagement;
