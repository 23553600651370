import { useNavigate } from "react-router-dom";

export const useNav = () => {
  const navigate = useNavigate();

  return {
    viewUsers: () => navigate(`/users/`),
    viewUser: (id: string) => navigate(`/users/${id}`),
    viewPost: (id: string) => navigate(`/posts/${id}/`),
    viewPostComment: (id: string, cid: string, rid?: string | null) =>
      navigate(`/posts/${id}/?cid=${cid}${rid ? `&rid=${rid}` : ""}`),
  };
};
