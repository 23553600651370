import { useEffect, useState } from "react";

export const useSearch = (value: any, setValue: (value: any) => void, timeout?: number) => {
  const [query, setQuery] = useState(value);
  const interval = timeout || 1000;

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(query), interval);
    return () => clearTimeout(timeoutId);
  }, [setValue, interval, query]);

  return [query, setQuery];
};
