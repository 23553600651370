import { Box, Paper, Typography } from "@mui/material";
import { PostData } from "../types/Post";

import {
  Wrapper,
  ItemCard,
  TagCard,
  TagUserCard,
  UserCard,
  DateCard,
  LikeCard,
  GeoCard,
} from "./Cards";
import MediaCard from "./MedaiCard";

const PostCardLong = ({ post }: { post: PostData }) => {
  const { author, createdAt, media, likes, description, tags, tagUsers, items, geoPoint } = post;

  const Author = <UserCard user={author} />;

  const CreatedDate = <DateCard date={createdAt} />;

  const Likes = <LikeCard count={likes.length} />;

  const Media = <MediaCard media={media} />;

  const Description = description ? <Typography>{description}</Typography> : null;

  const GeoPoint = <GeoCard geoPoint={geoPoint} />;

  const Items =
    items && items.length ? (
      <Wrapper>
        {items.map((item, i: number) => (
          <ItemCard key={i} {...item} />
        ))}
      </Wrapper>
    ) : null;

  const Tags =
    tags && tags.length ? (
      <Wrapper>
        {tags.map((tag, i: number) => (
          <TagCard key={i} tag={tag} />
        ))}
      </Wrapper>
    ) : null;

  const TagUsers =
    tagUsers && tagUsers.length ? (
      <Wrapper>
        {tagUsers.map((user, i: number) => (
          <TagUserCard key={i} user={user} />
        ))}
      </Wrapper>
    ) : null;

  return (
    <Paper sx={{ p: 1, borderRadius: "10px" }}>
      {Author}
      {Media}
      {Description}
      {Items}
      {Tags}
      {TagUsers}
      {GeoPoint}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {CreatedDate}
        {Likes}
      </Box>
    </Paper>
  );
};

export default PostCardLong;
