import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Between, DateCard, UserCard } from "../../components/Cards";
import Loader from "../../components/Loader";
import ShowMoreButton from "../../components/ShowMoreButton";
import { useNav } from "../../hooks/useNav";
import {
  getReportsAction,
  setReportsTypeAction,
  setReportsStatusAction,
  updateReportsStatusAction,
} from "../../store/slices/reports";
import { RootState } from "../../store/store";
import { ReportData, ReportTargetData } from "../../types/Report";

const reportTypes = [
  { label: "ALL", value: "" },
  { label: "FEEDBACK", value: "FEEDBACK" },
  { label: "GENERAL", value: "GENERAL" },
  { label: "COMMENT", value: "COMMENT" },
  { label: "POST", value: "POST" },
  { label: "USER", value: "USER" },
];
const reportStatuses = [
  { label: "ALL", value: "" },
  { label: "PENDING", value: "PENDING" },
  { label: "DECLINED", value: "DECLINED" },
  { label: "APPROVED", value: "APPROVED" },
];

const statusColors = {
  PENDING: "warning",
  DECLINED: "error",
  APPROVED: "success",
};

const Filter = ({ value, options, label, onChange }: any) => (
  <Box sx={{ width: 300, mr: 1 }}>
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        id={label}
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((el: any) => (
          <MenuItem value={el.value} key={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const ReportsFilter = ({ type, status, setType, setStatus }: any) => (
  <Paper sx={{ display: "flex", flexWrap: "wrap", p: 2 }}>
    <Filter value={type} options={reportTypes} label={"Report Types"} onChange={setType} />
    <Filter
      value={status}
      options={reportStatuses}
      label={"Report Statuses"}
      onChange={setStatus}
    />
  </Paper>
);

const ReportCard = ({
  report,
  setApproved,
  setDeclined,
  viewTarget,
}: {
  report: ReportData;
  setApproved: (id: string) => void;
  setDeclined: (id: string) => void;
  viewTarget: (type: string, target: null | string | ReportTargetData) => void;
}) => (
  <Paper sx={{ mt: 1, mb: 1 }}>
    <Alert color={(statusColors as any)[report.status]}>{report.status}</Alert>
    <Box sx={{ p: 2 }}>
      <Between>
        <UserCard user={report.author} />
        <Typography variant='caption'>{report.type}</Typography>
      </Between>
      <Typography>{report.message}</Typography>
      <Between sx={{ flexWrap: "wrap" }}>
        <DateCard date={report.createdAt} />
        <ButtonGroup>
          {report.target ? (
            <Button color='info' onClick={() => viewTarget(report.type, report.target)}>
              View Target
            </Button>
          ) : null}
          {report.status === "PENDING" ? (
            <Button color='success' onClick={() => setApproved(report.id)}>
              Mark as Approved
            </Button>
          ) : null}
          {report.status === "PENDING" ? (
            <Button color='error' onClick={() => setDeclined(report.id)}>
              Mark as Declined
            </Button>
          ) : null}
        </ButtonGroup>
      </Between>
    </Box>
  </Paper>
);

const Reports = () => {
  const dispatch = useDispatch();
  const { viewUser, viewPost, viewPostComment } = useNav();

  const { isLoading, list, last, type, status } = useSelector((state: RootState) => state.reports);

  useEffect(() => {
    dispatch(getReportsAction({ type, status }));
  }, [dispatch, type, status]);

  const handleViewTarget = (type: string, target: null | string | ReportTargetData) => {
    if (target !== null) {
      if (type === "POST" && typeof target === "string") {
        viewPost(target);
      }
      if (type === "USER" && typeof target === "string") {
        viewUser(target);
      }
      if (type === "COMMENT" && typeof target === "object") {
        const { id, comment_id, reply_id } = target;
        viewPostComment(id, comment_id, reply_id);
      }
    }
  };

  const handleSetStatus = (status: "APPROVED" | "DECLINED") => (id: string) => {
    dispatch(updateReportsStatusAction({ id, status }));
  };

  return (
    <Box>
      <Loader open={isLoading} />
      <ReportsFilter
        type={type}
        status={status}
        setType={(v: string) => dispatch(setReportsTypeAction(v))}
        setStatus={(v: string) => dispatch(setReportsStatusAction(v))}
      />
      {list.map((el) => (
        <ReportCard
          report={el}
          key={el.id}
          setApproved={handleSetStatus("APPROVED")}
          setDeclined={handleSetStatus("DECLINED")}
          viewTarget={handleViewTarget}
        />
      ))}
      {last ? <ShowMoreButton /> : null}
    </Box>
  );
};

export default Reports;
