import { Avatar, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { getTimeSincePost } from "../services/Date";
import { getMediaUrl } from "../services/Media";
import { MediaData } from "../types/Post";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RoomIcon from "@mui/icons-material/Room";
import { SxProps } from "@mui/system";
import { useNav } from "../hooks/useNav";

export const Wrapper = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>{children}</Box>
);

export const Between = ({ children, sx = {} }: { children: ReactNode; sx?: SxProps }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const Center = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{children}</Box>
);

export const GeoCard = ({ geoPoint }: any) =>
  geoPoint?.title ? (
    <Typography
      variant='body2'
      sx={{
        mt: 1,
        mb: 1,
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
      }}
    >
      <RoomIcon color={"primary"} sx={{ mr: "3px" }} fontSize='small' />
      {geoPoint.title}
    </Typography>
  ) : null;

export const ItemCard = ({ mark_name, model_name, year }: any) => (
  <Typography variant='body2' color={"gold"} sx={{ mr: 1 }}>
    {`${mark_name} (${model_name}, ${year}) `}
  </Typography>
);

export const TagCard = ({ tag }: { tag: string }) => (
  <Typography variant='body2' color={"blue"} sx={{ mr: 1 }}>{`#${tag} `}</Typography>
);

export const TagUserCard = ({ user }: any) => (
  <Typography
    variant='body2'
    color={"blue"}
    sx={{ mr: 1 }}
  >{`@${user.firstName}_${user.lastName} `}</Typography>
);

export const UserCard = ({ user }: any) => {
  const { viewUser } = useNav();
  return (
    <Box
      sx={{ display: "flex", p: 1, alignItems: "center", cursor: "pointer" }}
      onClick={() => viewUser(user.id)}
    >
      <Box sx={{ mr: 2 }}>
        <Avatar
          alt={`${user.username}-avatar`}
          src={getMediaUrl(user.photoURL)}
          sx={{ width: 50, height: 50 }}
        />
      </Box>
      <Box>
        <Typography variant='h5'>
          {user.firstName} {user.lastName} (@{user.username})
        </Typography>
      </Box>
    </Box>
  );
};

export const DateCard = ({ date }: any) => (
  <Typography variant='body2' color={"GrayText"} sx={{ mr: 1 }}>
    {getTimeSincePost(date)}
  </Typography>
);

export const LikeCard = ({ count }: { count: number }) => (
  <Typography
    sx={{
      m: 1,
      display: "flex",
      alignItems: "center",
      fontWeight: 700,
      color: "GrayText",
    }}
  >
    <FavoriteIcon color={"primary"} sx={{ mr: "3px" }} />
    {count}
  </Typography>
);

export const ImageCard = ({ file }: { file: MediaData }) => {
  return (
    <Center>
      <Box
        id={file.id}
        component='img'
        src={getMediaUrl(file.mediaUrl)}
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "500px",
          maxWidth: "500px",
          borderRadius: "10px",
        }}
      />
    </Center>
  );
};

export const VideoCard = ({ file }: { file: MediaData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        style={{
          width: 500,
          height: 500,
          overflow: "hidden",
        }}
      >
        <video
          controls
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
          src={getMediaUrl(file.mediaUrl)}
        />
      </Box>
    </Box>
  );
};
