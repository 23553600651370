import { combineReducers } from "redux";
import users from "./slices/users";
import user from "./slices/user";
import invitations from "./slices/invitations";
import reports from "./slices/reports";
import post from "./slices/post";
import roles from "./slices/roles";

const rootReducer = combineReducers({
  users,
  user,
  invitations,
  reports,
  post,
  roles,
});

export { rootReducer };
