import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../provider/AuthProvider";
import LoginPage from "../views/LoginPage";
import { Layout } from "../screens/Layout";
import Users from "../screens/users/Users";
import User from "../screens/user/User";
import Invitation from "../screens/invitation/Invitation";
import Report from "../screens/reports/Reports";
// import HomePage from "../screens/home/HomePage";
import Post from "../screens/post/Post";
import PageNotFound from "../screens/PageNotFound";
import Administrating from "../screens/administrating/Administrating";

export const AppRouter = () => {
  return (
    <Router>
      <Toaster />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route
            index
            element={
              <RequireAuth>
                <Navigate to='/users' />
              </RequireAuth>
            }
          />

          <Route
            path='login'
            element={
              <RedirectFromLoginIfAuthorized>
                <LoginPage />
              </RedirectFromLoginIfAuthorized>
            }
          />

          <Route path='users'>
            <Route
              index={true}
              element={
                <RequireAuth>
                  <CheckPermission permissions={"users"}>
                    <Users />
                  </CheckPermission>
                </RequireAuth>
              }
            />
            <Route
              path=':userId'
              element={
                <RequireAuth>
                  <CheckPermission permissions={"users"}>
                    <User />
                  </CheckPermission>
                </RequireAuth>
              }
            />
          </Route>

          <Route path='posts'>
            <Route
              path=':postId'
              element={
                <RequireAuth>
                  <CheckPermission permissions={"users"}>
                    <Post />
                  </CheckPermission>
                </RequireAuth>
              }
            />
          </Route>

          <Route
            path='invitations'
            element={
              <RequireAuth>
                <CheckPermission permissions={"invitations"}>
                  <Invitation />
                </CheckPermission>
              </RequireAuth>
            }
          />

          <Route
            path='reports'
            element={
              <RequireAuth>
                <CheckPermission permissions={"reports"}>
                  <Report />
                </CheckPermission>
              </RequireAuth>
            }
          />

          <Route
            path='administrating'
            element={
              <RequireAuth>
                <CheckPermission permissions={"administrating"}>
                  <Administrating />
                </CheckPermission>
              </RequireAuth>
            }
          />

          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

const RedirectFromLoginIfAuthorized = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (auth.user) {
    return <Navigate to='/users' state={{ from: location }} />;
  }
  return children;
};

const CheckPermission = ({
  children,
  permissions,
}: {
  children: JSX.Element;
  permissions?: any;
}) => {
  const auth = useAuth();
  const location = useLocation();
  const sectionAccess = auth?.permissions?.sectionAccess;
  const canView = sectionAccess ? (sectionAccess as any)[permissions] : false;
  if (!canView) {
    return <Navigate to='/' state={{ from: location }} />;
  }
  return children;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.user) {
    return <Navigate to='/login' state={{ from: location }} />;
  }
  return children;
};
