import { Avatar, Box, Paper, Tooltip, Typography } from "@mui/material";
import { getMediaUrl } from "../../services/Media";
import { ellipsis } from "../../services/Text";
import { UserCardData } from "../../types/User";

const UserCard = ({ user }: { user: UserCardData }) => {
  return (
    <Paper sx={{ cursor: "pointer", maxWidth: "100%", borderRadius: "50px 10px 10px 50px" }}>
      <Box sx={{ display: "flex", p: 1, maxWidth: "100%" }}>
        <Box sx={{ mr: 2 }}>
          <Avatar
            alt={`${user.userName}-avatar`}
            src={getMediaUrl(user.photoURL)}
            sx={{ width: 100, height: 100 }}
          />
        </Box>
        <Box>
          <Tooltip title={user.fullName}>
            <Typography sx={{ fontWeight: 600 }}>{ellipsis(user.fullName, 30)}</Typography>
          </Tooltip>
          <Typography>Username: {user.userName}</Typography>
          <Typography>Email: {user.email}</Typography>
          <Typography>Title: {user.title}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserCard;
