import apiClient from "../utils/axios";

export interface NotificationData {
  uid: string;
  type: string;
  metadata: {
    type: string;
    id: string;
  };
  target: {
    username: string;
    message: string;
  };
}

export const sendNotification = async (data: NotificationData) => {
  return await apiClient.post("/notification/", data);
};
