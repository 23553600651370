import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNav } from "../../hooks/useNav";
import UserCard from "./UserCard";
import ShowMoreButton from "../../components/ShowMoreButton";
import SearchBar from "../../components/SearchBar";
import Loader from "../../components/Loader";
import { RootState } from "../../store/store";
import { getUsersAction } from "../../store/slices/users";
import { UserCardData } from "../../types/User";

const Users = () => {
  const dispatch = useDispatch();
  const { viewUser } = useNav();

  // query with reset on unmount
  const [query, setQuery] = useState("");

  const { list, isLoading, last } = useSelector((state: RootState) => state.users);

  const handleSetQuery = (q: string) => {
    // dispatch(setQueryAction(q));
    setQuery(q);
  };

  useEffect(() => {
    dispatch(getUsersAction({ query }));
  }, [dispatch, query]);

  const usersList = list.map((item: UserCardData) => (
    <Grid item sm={12} md={6} lg={4} key={item.id}>
      <Box onClick={() => viewUser(item.id)}>
        <UserCard user={item} />
      </Box>
    </Grid>
  ));

  return (
    <Box>
      <Loader open={isLoading} />
      <Box sx={{ mb: 2 }}>
        <SearchBar value={query} setValue={handleSetQuery} />
      </Box>
      <Grid container spacing={1}>
        {usersList}
      </Grid>
      {last ? <ShowMoreButton /> : null}
    </Box>
  );
};

export default Users;
