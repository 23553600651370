import { collection, query, where, addDoc, updateDoc, doc, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { useCollectionData, useCollectionOnce } from "react-firebase-hooks/firestore";
import { useAuth } from "../provider/AuthProvider";
import { sendNotification } from "../requests/notifications";

const getQueryRef = (sortedIdList: any[]) => {
  return query(collection(db, "message-threads"), where("users", "==", sortedIdList));
};

export const useChatCheck = (userId?: string) => {
  const { user } = useAuth();
  const sortedIdList = [userId, user?.uid].sort();
  const q = getQueryRef(sortedIdList);
  const [snapshot, loading, error] = useCollectionOnce(q);

  return {
    isCheckingChats: loading,
    isChatExist: !snapshot?.empty,
    chatId: !snapshot?.empty && snapshot?.docs[0] ? snapshot?.docs[0].id : null,
    checkingError: error?.message || null,
    sortedIdList,
  };
};

export const useMessages = (chatId?: string) => {
  const q = query(
    collection(db, `message-threads/${chatId}/messages`),
    orderBy("createdAt", "desc")
  );
  const [snapshot, loading, error] = useCollectionData(q);
  return [snapshot, loading, error?.message];
};

export const sendMessageToChat = async ({
  thread_id,
  targetId,
  authorId,
  message,
}: {
  thread_id: string;
  targetId: string;
  authorId: string;
  message: string;
}) => {
  await addDoc(collection(db, `message-threads/${thread_id}/messages`), {
    text: message,
    createdAt: new Date().getTime(),
    user: { _id: authorId },
    received: false,
    sent: true,
  });
  await updateDoc(doc(db, `message-threads/${thread_id}`), {
    latestMessageReceived: false,
    latestMessage: {
      text: message,
      createdAt: new Date().getTime(),
      user: { _id: authorId },
    },
  });
  await sendNotification({
    uid: targetId,
    type: "new-message",
    metadata: { type: "messages", id: thread_id },
    target: { username: "Admin", message: message },
  });
};

export const createChat = async ({
  targetId,
  authorId,
  message,
}: {
  targetId: string;
  authorId: string;
  message: string;
}) => {
  const sortedIdList = [targetId, authorId].sort();
  const docRef = await addDoc(collection(db, "message-threads"), {
    users: sortedIdList,
    latestMessageReceived: false,
    latestMessage: {
      text: message,
      createdAt: new Date().getTime(),
      user: { _id: authorId },
    },
  });
  const thread_id = docRef.id;
  await addDoc(collection(db, `message-threads/${thread_id}/messages`), {
    text: message,
    createdAt: new Date().getTime(),
    user: { _id: authorId },
    received: false,
    sent: true,
  });
  await sendNotification({
    uid: targetId,
    type: "new-message",
    metadata: { type: "messages", id: thread_id },
    target: { username: "Admin", message: message },
  });
  return docRef.id;
};
