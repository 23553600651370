import { createSlice } from "@reduxjs/toolkit";
import { getReports, updateReport } from "../../requests/reports";
import { getUpdatedList } from "../../services/Data";
import { ReportData } from "../../types/Report";
import { AppDispatch, RootState } from "../store";

interface InitialStateType {
  isLoading: boolean;
  error: null | string;
  list: ReportData[];
  last: null | number;
  count: null | number;
  type: string;
  status: string;
}

const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  list: [],
  last: null,
  count: null,
  type: "",
  status: "",
};

const slice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setReportsListSuccess(state, action) {
      state.list = action.payload.data;
      state.last = action.payload.last;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    setReportsListError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setReportsType(state, action) {
      state.type = action.payload;
    },
    setReportsStatus(state, action) {
      state.status = action.payload;
    },
    setReportOnUpdateSuccess(state, action) {
      state.list = action.payload;
      state.isLoading = false;
    },
  },
});

export const setReportsTypeAction = (value: string) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setReportsType(value));
};

export const setReportsStatusAction = (value: string) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setReportsStatus(value));
};

export const getReportsAction =
  (params: { type?: string; from?: string | number; status?: string }) =>
  (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading());
    getReports(params)
      .then((res) => dispatch(slice.actions.setReportsListSuccess(res)))
      .catch((err) => dispatch(slice.actions.setReportsListError(err)));
  };

export const updateReportsStatusAction =
  (params: { id: string; status: string }) =>
  (dispatch: AppDispatch, getStore: () => RootState) => {
    const { reports } = getStore();
    const updated = getUpdatedList(reports.list, params);
    dispatch(slice.actions.setLoading());
    updateReport(params.id, { status: params.status })
      .then((res) => dispatch(slice.actions.setReportOnUpdateSuccess(updated)))
      .catch((err) => dispatch(slice.actions.setReportsListError(err)));
  };

export default slice.reducer;
