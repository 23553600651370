import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as EmailValidator from "email-validator";
import { MessageMailData, TemplateData } from "../../types/Invites";
import template from "../../constants/template";
import { Filter, Input } from "../../components/FormFields";

const MailCreationModal = ({
  open,
  onClose,
  onSubmit,
  onSaveTemplate,
  templates,
  link,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: MessageMailData) => void;
  onSaveTemplate: (data: TemplateData) => void;
  templates: TemplateData[];
  link?: string;
}) => {
  const theme = useTheme();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [toEmail, setToEmail] = useState("");
  const [currentTemplateTitle, setCurrentTemplateTitle] = useState("");
  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const html = link ? template.replace(`{{ content }}`, content).replace("{{ link }}", link) : "";
  const disabled = !currentTemplateTitle.length;

  const options = useMemo(
    () => [
      { label: "* New one *", value: null, html: null },
      ...templates.map((el) => ({ label: el.title, value: el.id, html: el.html })),
    ],
    [templates]
  );

  const handleSaveTemplate = (template_id: null | string) => async () => {
    onSaveTemplate({
      id: template_id,
      title: currentTemplateTitle,
      html: content,
    });
  };

  const handleSubmit = () => {
    if (EmailValidator.validate(toEmail)) {
      onSubmit({
        to: toEmail,
        message: {
          subject: currentTemplateTitle,
          html: html,
        },
      });
    } else {
      alert("Incorrect email");
    }
  };

  useEffect(() => {
    if (currentTemplate) {
      const current = options.find((el) => el.value === currentTemplate) || options[0];
      const html = current?.html;
      const title = current?.label;
      if (html && title) {
        setCurrentTemplateTitle(title);
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    } else {
      setEditorState(EditorState.createEmpty());
      setCurrentTemplateTitle("");
    }
  }, [currentTemplate, options]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle sx={{ fontSize: "1.5em" }}>Send invitation mail</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex" }}>
          <Box>
            <DialogContentText>
              You can send a invitation link by mail. Please specify the text of the mail or use a
              pre-made template. Link will be attached automatically in the mail bottom.
            </DialogContentText>
            <Box sx={{ mt: 2, width: "100%" }}>
              <Input value={toEmail} onChange={setToEmail} label='To' />
              <Filter
                value={currentTemplate}
                onChange={setCurrentTemplate}
                label='Templates'
                options={options}
              />
              <Input
                value={currentTemplateTitle}
                onChange={setCurrentTemplateTitle}
                label='Subject'
              />
              <ButtonGroup>
                {currentTemplate && (
                  <Button
                    variant='outlined'
                    onClick={handleSaveTemplate(currentTemplate)}
                    disabled={disabled}
                  >
                    Update Current Template
                  </Button>
                )}
                <Button variant='outlined' onClick={handleSaveTemplate(null)} disabled={disabled}>
                  Save New Template
                </Button>
                <Button variant='outlined' onClick={() => setPreviewOpen(!isPreviewOpen)}>
                  {!isPreviewOpen ? "Show Preview" : "Hide Preview"}
                </Button>
              </ButtonGroup>
              <Box
                sx={{ background: theme.palette.background.default, minHeight: 400, mt: 1, p: 1 }}
              >
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbar={{
                    options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign"],
                  }}
                />
              </Box>
            </Box>
          </Box>
          {isPreviewOpen && (
            <Box sx={{ background: theme.palette.background.default, p: 1, ml: 1, maxWidth: 320 }}>
              <Box sx={{ background: "white" }}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant='contained'>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MailCreationModal;
