import { BASE_BUCKET_URL, BASE_URL } from "../constants";

export const getMediaUrl = (mediaPath: string | null, fromBack?: boolean) => {
  if (mediaPath) {
    if (fromBack) return `${BASE_URL}${mediaPath}`;
    const id = mediaPath.slice(5);
    return `${BASE_BUCKET_URL}${id}`;
  }
  return "";
};
