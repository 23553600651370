import React from "react";

export const useScrollList = (list: any[]) => {
  const refs = list.length
    ? list.reduce((acc: any, value: any) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {})
    : [];

  const handleScroll = (id: string) => {
    if (refs[id]) {
      refs[id].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return { refs, handleScroll };
};
