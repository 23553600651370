import { TextField } from "@mui/material";
import { useSearch } from "../hooks/useSearch";

interface SearchBarProps {
  value: any;
  setValue: (value: any) => void;
  timeout?: number;
}

const SearchBar = ({ value, setValue, timeout }: SearchBarProps) => {
  const [query, setQuery] = useSearch(value, setValue, timeout);
  return (
    <TextField
      fullWidth
      label='Search'
      size='small'
      variant='outlined'
      value={query}
      onChange={(e) => setQuery(e.target.value)}
    />
  );
};
export default SearchBar;
