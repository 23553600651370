import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface PostDeleteModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
}

const PostDeleteModal = ({ open, onClose, onSubmit }: PostDeleteModalProps) => {
  const [message, setMessage] = useState("");
  const handleSubmit = () => onSubmit(message);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle sx={{ fontSize: "1.5em" }}>Post Deleting</DialogTitle>
      <DialogContent>
        <DialogContentText>Specify the reason for deleting the post</DialogContentText>
        <TextField
          fullWidth
          autoFocus
          multiline
          minRows={5}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant='contained' disabled={!message}>
          Send Notification and Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostDeleteModal;
