import axios from "axios";
import { BASE_URL, UNAUTHORIZED } from "../constants";
import { auth } from "../firebase";

const apiClient = axios.create({
  timeout: 15000,
  baseURL: BASE_URL,
});

export const setTokenInstance = (accessToken: string | undefined, reqConfig?: any) => {
  if (accessToken) {
    const Bearer = `Bearer ${accessToken}`;
    apiClient.defaults.headers.common.Authorization = Bearer;

    if (reqConfig) {
      console.log(reqConfig);
      return apiClient.request({
        ...reqConfig,
        headers: {
          ...reqConfig.headers,
          Authorization: Bearer,
        },
      });
    }
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

apiClient.interceptors.response.use(
  async (response) => {
    // if (auth?.currentUser) {
    //   response.headers.token = await auth?.currentUser.getIdToken();
    // }
    return response;
  },
  async (error) => {
    if (error?.response?.status === UNAUTHORIZED && auth?.currentUser) {
      const tokenResult = await auth?.currentUser?.getIdTokenResult();
      if (tokenResult?.token) return setTokenInstance(tokenResult.token, error.config);
    }
    // console.log(error?.response?.data?.message || error?.response || "Something went wrong");

    throw new Error(error?.response?.data?.message || error?.response || "Something went wrong");
  }
);

export default apiClient;
