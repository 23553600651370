import AuthProvider from "./provider/AuthProvider";
import { ThemeProvider } from "@mui/material/styles";
import { AppRouter } from "./router";
import theme from "./theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
