/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Box, Typography, Divider } from "@mui/material";
import { useEffect } from "react";
import CommentCard from "../../components/CommentCard";
import EmptyBox from "../../components/EmptyBox";
import { useScrollList } from "../../hooks/useScrollList";
import { CommentData } from "../../types/Post";
import { PostSearchParams } from "./Post";

const CommentsList = ({
  comments,
  isLoading,
  postId,
  searchParams,
}: {
  comments: CommentData[];
  isLoading: boolean;
  postId: string;
  searchParams: PostSearchParams;
}) => {
  const { refs, handleScroll } = useScrollList(comments);

  useEffect(() => {
    if (comments.length && searchParams.cid) {
      handleScroll(searchParams.cid);
    }
  }, [comments, searchParams]);

  return (
    <Paper
      sx={{
        mb: 1,
        p: 1,
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant='h3' sx={{ mt: 1, mb: 1 }}>
        Comments
      </Typography>
      <Divider sx={{ mb: 1 }} />
      {comments.length === 0 ? (
        <EmptyBox isLoading={isLoading} />
      ) : (
        <Box sx={{ overflowY: "scroll", height: "100%" }}>
          {comments.map((el) => (
            <div ref={refs[el.id]} key={el.id}>
              <CommentCard comment={el} postId={postId} searchParams={searchParams} />
            </div>
          ))}
        </Box>
      )}
    </Paper>
  );
};
export default CommentsList;
