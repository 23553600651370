import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { MediaData } from "../types/Post";
import { ImageCard, VideoCard } from "./Cards";

const useSteps = (total: number) => {
  const [index, setIndex] = useState(0);
  const last = total > 0 ? total - 1 : 0;
  const nextDisabled = index === last;
  const backDisabled = index === 0;
  const next = () => {
    !nextDisabled && setIndex(index + 1);
  };
  const back = () => {
    !backDisabled && setIndex(index - 1);
  };

  return { index, next, back, nextDisabled, backDisabled };
};

const Actions = ({
  next,
  back,
  nextDisabled,
  backDisabled,
}: {
  next: () => void;
  back: () => void;
  nextDisabled: boolean;
  backDisabled: boolean;
}) => {
  if (backDisabled && nextDisabled) return null;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button disabled={backDisabled} onClick={back}>
        Back
      </Button>
      <Button disabled={nextDisabled} onClick={next}>
        Next
      </Button>
    </Box>
  );
};

const MediaCard = ({ media }: { media: MediaData[] }) => {
  const { index, next, back, nextDisabled, backDisabled } = useSteps(media.length);
  const [currentMedia, setCurrentMedia] = useState(media[0]);

  useEffect(() => {
    setCurrentMedia(media[index]);
  }, [media, index]);

  const isVideo = currentMedia.mimeType.includes("video");

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      {isVideo ? <VideoCard file={currentMedia} /> : <ImageCard file={currentMedia} />}
      <Actions next={next} back={back} nextDisabled={nextDisabled} backDisabled={backDisabled} />
    </Box>
  );
};

export default MediaCard;
