import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DeleteButton from "../../components/DeleteButton";
import Loader from "../../components/Loader";
import PostCardLong from "../../components/PostCardLong";
import { useNav } from "../../hooks/useNav";
import { deletePost } from "../../requests/post";
import { getPostAction, getPostCommentsAction } from "../../store/slices/post";
import { RootState } from "../../store/store";
import PageNotFound from "../PageNotFound";
import CommentsList from "./CommentsList";
import PostDeleteModal from "./PostDeleteModal";

const PostDeleteCard = ({ id, onPostDeleted }: { id: string; onPostDeleted: () => void }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async (message: string) => {
    setOpen(false);
    setLoading(true);
    await deletePost(id, { message });
    setLoading(false);
    onPostDeleted();
  };

  return (
    <Paper sx={{ p: 1, borderRadius: "10px", mt: 1 }}>
      <Loader open={loading} />
      <PostDeleteModal open={open} onClose={() => setOpen(false)} onSubmit={handleDelete} />
      <DeleteButton onDelete={() => setOpen(true)} />
    </Paper>
  );
};

export interface PostSearchParams {
  cid: string | null;
  rid: string | null;
}

const Post = () => {
  const nav = useNav();
  const params = useParams();
  const search = new URLSearchParams(useLocation().search);
  const searchParams = {
    cid: search.get("cid"),
    rid: search.get("rid"),
  };

  const postId = params?.postId;

  const dispatch = useDispatch();

  const { post, comments, isPostLoading, isCommentsLoading } = useSelector(
    (state: RootState) => state.post
  );

  const redirectAfterDelete = () => {
    if (post?.author.id) {
      nav.viewUser(post?.author.id);
    }
  };

  useEffect(() => {
    if (postId) {
      dispatch(getPostAction({ id: postId }));
      dispatch(getPostCommentsAction({ id: postId }));
    }
  }, [dispatch, postId]);

  useEffect(() => {
    if (postId) {
      dispatch(getPostAction({ id: postId }));
      dispatch(getPostCommentsAction({ id: postId }));
    }
  }, [dispatch, postId]);

  if (isPostLoading) return <Loader />;
  if (postId && post?.id)
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <PostCardLong post={post} />
          <PostDeleteCard id={postId} onPostDeleted={redirectAfterDelete} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommentsList
            comments={comments}
            postId={postId}
            isLoading={isCommentsLoading}
            searchParams={searchParams}
          />
        </Grid>
      </Grid>
    );
  return <PageNotFound text={"Post not found"} />;
};

export default Post;
