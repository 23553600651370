import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Between, UserCard } from "../../components/Cards";
import { useAuth } from "../../provider/AuthProvider";
import {
  createInvitationAction,
  getInvitationsAction,
  getTemplatesAction,
  saveTemplatesAction,
  sendEmailAction,
} from "../../store/slices/invitations";
import { RootState } from "../../store/store";
import { InviteData, MessageMailData, TemplateData } from "../../types/Invites";
import Loader from "../../components/Loader";
import ScrollList from "../../components/ScrollList";
import InviteBar from "./InviteBar";
import MailCreationModal from "./MailCreationModal";
import MailStatusModal from "./MailStatusModal";

const Invitation = () => {
  const { user } = useAuth();
  const userId = user?.uid;
  const dispatch = useDispatch();

  const { invitedUsers, invitesUsed, invitesCount, isLoading, templates, isTemplatesLoading } =
    useSelector((state: RootState) => state.invitations);

  const handleCreateLink = () => {
    if (userId) dispatch(createInvitationAction(userId));
  };

  useEffect(() => {
    dispatch(getInvitationsAction());
    dispatch(getTemplatesAction());
  }, [dispatch]);

  const pendingInvites = invitedUsers.filter((el) => el.status === "pending");
  const liveInvites = invitedUsers.filter((el) => el.status !== "pending" && el.profile);

  console.log({ pendingInvites, liveInvites, invitedUsers });

  const [creationOpen, setCreationOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [currentInvite, setCurrentInvite] = useState<null | InviteData>(null);

  const handleOpenCreation = (invite: InviteData) => {
    setCurrentInvite(invite);
    setCreationOpen(true);
  };

  const handleCloseCreation = () => {
    setCurrentInvite(null);
    setCreationOpen(false);
  };

  const handleSubmitCreation = (values: MessageMailData) => {
    setCreationOpen(false);
    if (currentInvite?.invite_id) {
      dispatch(
        sendEmailAction({ invite_id: currentInvite.invite_id, mail_data: values }, currentInvite)
      );
    }
  };

  const handleSaveTemplate = (data: TemplateData) => {
    dispatch(saveTemplatesAction(data));
  };

  return (
    <Box>
      <Loader open={isLoading && isTemplatesLoading} />
      <MailCreationModal
        open={Boolean(currentInvite?.link && creationOpen)}
        link={currentInvite?.link}
        onClose={handleCloseCreation}
        onSubmit={handleSubmitCreation}
        onSaveTemplate={handleSaveTemplate}
        templates={templates}
      />
      <MailStatusModal open={statusOpen} onClose={() => setStatusOpen(false)} />
      <Paper sx={{ p: 2, borderRadius: "10px", mb: 1 }}>
        <Between>
          <Typography>{"Invites left"}</Typography>
          <Typography>{`${invitesCount}`}</Typography>
        </Between>
        <Between>
          <Typography>{"Invites used"}</Typography>
          <Typography>{`${invitesUsed}`}</Typography>
        </Between>
        <Button onClick={handleCreateLink} fullWidth variant='contained' sx={{ mt: 1 }}>
          Create New
        </Button>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <ScrollList
            title={"Active Invites (click to copy)"}
            list={pendingInvites}
            renderItem={(el) => (
              <InviteBar invite={el} key={el.invite_id} onSend={handleOpenCreation} />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ScrollList
            title={"Invited Users"}
            list={liveInvites}
            renderItem={(el) => <UserCard user={el.profile} key={el.invite_id} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invitation;
