import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";

function TabPanel(props: { children: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface TabProps {
  label: string;
  Component: any;
  disabled?: boolean;
}

interface TabsContainerProps {
  initialIndex?: number;
  tabs: TabProps[];
}

const TabsContainer = ({ initialIndex = 0, tabs }: TabsContainerProps) => {
  const [activeTab, setActiveTab] = useState(initialIndex);

  const handleChange = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={activeTab} onChange={(_, val: number) => handleChange(val)}>
          {tabs.map(({ label, disabled }, i) => (
            <Tab label={label} {...getTabProps(i)} key={i} disabled={disabled} />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ Component }, i) => (
        <TabPanel value={activeTab} index={i} key={i}>
          <Component />
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsContainer;
