import { Box, Divider, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import EmptyBox from "./EmptyBox";

const ScrollList = ({
  list = [],
  title = "",
  renderItem,
}: {
  list: any[];
  title: string;
  renderItem: (...val: any) => ReactNode;
}) => {
  return (
    <Paper sx={{ mt: 1, p: 1, borderRadius: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
          mb: 1,
        }}
      >
        <Typography variant='h3' sx={{ mr: 2 }}>
          {title}
        </Typography>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box sx={{ overflowY: "scroll", minHeight: 490, height: "100%", p: 1 }}>
        {list.length === 0 ? <EmptyBox /> : list.map(renderItem)}
      </Box>
    </Paper>
  );
};

export default ScrollList;
