import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(240, 241, 245, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1200,
  },
  circle: {
    width: 150,
    height: 150,
    borderRadius: 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

const Loader = ({ open = true }: { open?: boolean }) => {
  const classes = useStyles();
  if (!open) return null;
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.circle}>
        <CircularProgress size={80} />
      </Box>
      <Box className={classes.text}>
        <Typography variant='h4'>Loading...</Typography>
      </Box>
    </Box>
  );
};

export default Loader;
